@use "sass:map";
@import "src/styles/common/font";
@import "src/styles/common/color";

.body-user {
  > span {
    font-weight: map-get($font-weight, "semi-bold");
    font-size: map-get($font-size, "28");
  }
  > .container-user {
    background: $cl-lbl-5;
    border-radius: 14px;
    margin-top: 24px;

    > .tab-user {
      padding: 0px;

      > p {
        font-weight: map-get($font-weight, "semi-bold");
        font-size: map-get($font-size, "18");
        padding: 24px 0px 0px 24px;
      }
    }

    .box-user {
      padding: 24px;
      display: flex;
      flex-direction: column;

      > ul {
        padding: 0px;
        list-style: none;
        display: flex;
        gap: 24px;
        > li {
          display: flex;
          flex-direction: column;
          gap: 8px;
          > p {
            color: $cl-lbl-2;
            font-family: map-get($font-weight, "medium");
          }
        }
      }

      > .btn-user {
        margin-left: auto;
        margin-top: 16px;
        display: flex;
        gap: 8px;
      }
    }
  }
}

.table-user-management {
  .header-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > span {
      font-weight: map-get($font-weight, "semi-bold");
      font-size: map-get($font-size, "28");
      color: $cl-lbl-1;
    }
  }

  .user-status-active {
    font-weight: map-get($font-weight, "regular") !important;
    font-size: map-get($font-size, "12") !important;
    color: $cl-primary !important;
  }
  .user-status-locked {
    font-weight: map-get($font-weight, "regular") !important;
    font-size: map-get($font-size, "12") !important;
    color: $cl-system-error !important;
  }

  .total-using-service {
    padding: 9px 12px 9px 12px;
    background-color: $cl-fill-2;
    color: $cl-lbl-1;
    border-radius: 8px;
    cursor: pointer;
  }

  .package-status {
    padding: 4px 10px 4px 10px;
    border-radius: 22px;
    font-size: 12px;
  }
  .package-status-no-active {
    background-color: $cl-separator-bg-yellow;
    color: $cl-lbl-1;
  }
  .package-status-activated {
    background-color: $cl-separator-bg-blue !important;
    color: $cl-system-process !important;
  }

  .package-status-expired {
    background-color: $cl-system-error;
    color: $cl-lbl-5 !important;
  }

  .package-status-expire_soon {
    background-color: $cl-separator-bg-red;
    color: $cl-system-error !important;
  }

  .package-status-reject {
    background-color: $cl-fill-3;
    color: $cl-lbl-1;
  }

}

.user-menu-dropdown { 
  font-weight: map-get($font-weight, "semi-bold");
  font-size: map-get($font-size, "15");
}

.user-menu-dropdown:hover {
  color: $cl-system-success;
  background-color: $cl-separator-bg-green;
}

.table-info-user {
  cursor: pointer;
}
